import React from 'react'

const PageTitle = ({pagetitle,curentpage,totalpages}) => {
  return (
    <div className='d-flex page-title box-shadow justify-content-between align-items-center'>
      <div className="text-blue-800 text-base px-4 flex items-center justify-between">
        <span>
        {pagetitle}

        </span>
    
      </div>
    </div>
  )
}

export default PageTitle
