import React from 'react';
import { ReactComponent as Chevronleft } from '../assets/images/ChevronLeft.svg';

const OtherPageTitle = ({ title, action }) => {
  return (

      <div className='flex bg-white page-header my-3 py-3 items-center'>
        <Chevronleft className='cursor-pointer' onClick={action} />
        <div className=' text-blue-900 text-base font-medium'>{title}</div>
      </div>
  );
};

export default OtherPageTitle;
