import React,{useEffect,useState} from 'react'
import { Form, FormControl, FormGroup, FormLabel,Modal,Button, useAccordionButton} from 'react-bootstrap'
import TableCommponnent from '../../components/TableCommponnent'
import PageTitleBar from '../../components/PageTitleBar'
import { ReactComponent as ThumbsUp } from '../../assets/images/thumbsup.svg'
import { ReactComponent as ThumbsDown } from '../../assets/images/thumbsdown.svg'
import { ReactComponent as ApprovalModalImage } from '../../assets/images/image 12.svg'
import ApprovalStatusImage from '../../assets/images/image 12.png'
import { NumericFormat } from 'react-number-format'
import {format} from 'date-fns'
import formatText from '../../components/formatText'
import axios from 'axios'
import Header from '../apprLyout/Topnav'
import Loader from '../../components/Loader'
import Sidenav from '../apprLyout/Sidenav'
import { useNavigate ,useLocation} from 'react-router-dom'
import Invoice from '../../components/Invoice';
import Error from '../../components/Error'
import SessionExpired from './sessionExpired'
const ApprovalPage = () => {
    // const {Invoice}=TableData
    const navigate=useNavigate()
    // const Invoice = localStorage.getItem('responseData') !=='undefined' ?JSON.parse(localStorage.getItem('responseData')) :[];
   const invoice=JSON.parse(localStorage.getItem('invoice'))
   console.log(invoice,'invoice')
    const [invoiceData,setInvoiceData] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sessionExpired,setsessionExpired]=useState(false)
  const [showMessage, setShowMessage] = useState(false);
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const Colour =  '#3513f1';
  const vendor_Signature='Vendor Signature'
  const [error, setError] = useState(null);
  // const Colour = vendordetails?.vendor_settings?.invoice_color ?? '#027C94';
  const location=useLocation()
    const token=localStorage.getItem('accessToken')
    const name = localStorage.getItem("name");
  const totalPages = Math.ceil(invoiceData?.length / itemsPerPage);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pageParam = params.get("page");
    const pageSizeParam = params.get("page_size");

    const newPage = pageParam ? Number(pageParam) : 1;
    const newPageSize = pageSizeParam ? Number(pageSizeParam) : 10;

    setPage(newPage);
    setSize(newPageSize);

    getSignables(newPage, newPageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);
   const getSignables = async (page,size) => {

    setLoading(true);
  
    try {
      const roles = JSON.parse(localStorage.getItem('roles')) || [];
      const isAdmin = roles.includes("dmd_ss");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}approvals/v1/${isAdmin ? 'any' : 'get'}/invoice/pending?page=${page}&size=${size}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      console.log(response.data.data, "I am the res");
      localStorage.setItem('responseData', JSON.stringify(response.data.data));
    setInvoiceData(response.data.data.results);
      setCount(response.data.data.count)
      const totalPages=Math.ceil(response.data.data.count/size)
      setTotal(totalPages)
    } catch (error) {
      console.log(error, "PO fetching error.");
          const errorMessage = error?.response?.data?.detail||'Error Fetching PO Items'
            ? error?.response?.data?.detail
            : 'Error Fetching PO';
  
            if (error?.response?.status === 401) {
              // Call handleLogout in case of 401 error
              // handleLogout(msalConfig, userName, navigate);
              setsessionExpired(true)
            }
          else{setError(errorMessage);
        }
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };
  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages]);
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleChangeItemsPerPage = (e) => {
    const selectedValue = parseInt(e.target.value);
  setItemsPerPage(selectedValue);
  setCurrentPage(1); // Reset to the first page when changing items per page
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = invoiceData?.slice(startIndex, endIndex);
  console.log(currentData,'current')
  const [activeItemId, setActiveItemId] = useState(null);
  const [comment, setComment] = useState('');
  const [sign,setSign]=useState(null)
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(1);
  const [count, setCount] = useState(1);
  const handleApprovalPreviousPage=(itemId)=>{
    
    if(activeItemId===null){
      navigate('/approval/home')
    }else{
      setActiveItemId(null)
    }
  }
  const handleButtonClick = (itemId) => {
    setActiveItemId(itemId);
    localStorage.setItem('invoice', JSON.stringify(itemId));
  };
 
  const signSignables = async (sign) => {
    try {
      if (!invoiceData || invoiceData.length === 0) {
        throw new Error("Invoice data is missing or incomplete.");
      }
  
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/approvals/v1/sign/invoice/${invoiceData[0].id}`,
        {
          signature: "",
          approved: sign,
          comment: comment,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      setApprovalMessage(`Invoice ${sign ? 'Approved' : 'Declined'} Successfully`);
      setConfirmationModalShow(true);
    } catch (error) {
      console.log(error, "Error during the invoice signing process.");
  
      if (error?.response?.status === 401) {
        setsessionExpired(true);
      } else {
        const errorMessage = error.response.data.message || 'Error with the request';
        setError(errorMessage);
        setApprovalMessage(errorMessage);
      }
      setConfirmationModalShow(true);
    } finally {
      setLoading(false);
    }
  };
  
  const [modalState, setModalState] = useState(false);
  const [approvalMessage, setApprovalMessage] = useState('');
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);
  const [action, setAction] = useState(null);
  
  const handleParentModalShow = (action) => {
    setAction(action);
    setModalState(true);
  };
  
  const handleModalConfirm = async () => {
    setModalState(false);
    setLoading(true);
    await signSignables(action === 'approve');
  };
  
  const handleCloseConfirmationModal = () => {
    setConfirmationModalShow(false);
    if (action === 'approve') {
      navigate('/approval/approved');
    } else {
      navigate('/approval/declined');
    }
  };
  return (
    <>
    {loading && <Loader/>}
    {sessionExpired ? <SessionExpired/>:
    <div>
         <Header />

<div className="flex  ">
  <div className=" hidden md:block">
    <Sidenav/>
  </div>
  <div className="content md:w-[82%] md:ml-[18%] lg:w-[86%] w-full lg:ml-[14%]  px-3">
<div>
<PageTitleBar
title={'Pending Invoices'}
onPrevPage={handleApprovalPreviousPage}
itemId={activeItemId}/>


{activeItemId? (
<div className='gap-3  flex overflow-x-auto' >


{/* <iframe 
src={`${process.env.PUBLIC_URL}/pdfs/pdfpage.html`}
frameborder="0"
width="100%"
height="950px"
title="PDF Viewer"

/> */}
<div className="w-[70%] bg-white flex flex-col py-4   overflow-auto" id="pdf">
<Invoice receipt={invoice}/>
</div>

<div className="w-[30%] rounded-lg">
<p className='approval-card-title d-flex align-items-center justify-content-center py-2 rounded-t-md'>Status:  Awaiting Payment Approval</p>
<div className="card  p-2">
<FormGroup className='mb-3'>
  <FormLabel className='text-20'>Role</FormLabel>
  <FormControl 
className=' pl-2 bg-gray-50 text-sm py-2'
placeholder='Role'
value={formatText(invoice.workflow.pending_approval_from)}
readOnly // Use readOnly to make it clear that this field is not editable
/>
  <FormControl 
  className='mt-3 pl-2 bg-gray-50 text-sm py-2'
  placeholder='Name'
  value={name}
  >

  </FormControl>
  <FormLabel className='mt-3'>Comments</FormLabel>
  <FormControl
    as="textarea"
    rows={4}
    placeholder="Comments"
    className='text-area bg-gray-50 text-sm py-2'
    onChange={(e)=>{setComment(e.target.value)}}
    value={comment}
   
  />
</FormGroup>
<div className="row card-action d-flex justify-content-between mx-4 my-2">
<button
className='col-6 d-flex justify-content-between align-items-center px-3 btn-green text-white bg-green-600 py-2 rounded w-32'
onClick={() => handleParentModalShow('approve')}
>
<div className="mt-n2"><ThumbsDown /></div>
<span>Approve</span>
</button>

<button
className={`col-6 d-flex justify-center gap-1 align-items-center px-3 rounded py-2 bg-red-500 text-white`}
onClick={() => {
if (comment === '') {
setShowMessage(true);
} else {
handleParentModalShow('decline');
}
}}
onMouseEnter={() => {
if (comment === '') {
setShowMessage(true);
}
}}
onMouseLeave={() => setShowMessage(false)}
>
<div className="mt-1"><ThumbsUp /></div>
<span>Decline</span>
</button>

{showMessage && comment === '' && (
<div className="text-red-600 text-sm mt-2">Comment is required to decline an invoice</div>
)}

<>



<Modal show={modalState} onHide={() => setModalState(false)} centered>
<Modal.Body>
<div className='d-flex align-items-center flex-column justify-content-center'>
<ApprovalModalImage/>
<Modal.Body>Are you sure you want to {action} this invoice?</Modal.Body>
<div className="d-flex gap-3 my-4">
<Button style={{backgroundColor: '#12AF2B',border:'none',borderRadius:'2px',width:'120px'}} onClick={handleModalConfirm}>
Yes
</Button>
<Button style={{backgroundColor: '#C00E19',border:'none',borderRadius:'2px',width:'120px'}}  onClick={() => setModalState(false)}>
No
</Button>
</div>


</div>
</Modal.Body>
</Modal>
{error? <Error message={approvalMessage} show={confirmationModalShow} handleShow={handleCloseConfirmationModal}/>
:
<Modal show={confirmationModalShow} onHide={handleCloseConfirmationModal} centered>

<Modal.Body>
<div className='d-flex align-items-center flex-column justify-content-center'>
<img src={ApprovalStatusImage} alt="" />

<p className='approval-status-mesage py-2'>{approvalMessage}</p>
<Button className='mb-4'style={{backgroundColor: '#12AF2B',border:'none',borderRadius:'2px',width:'120px'}} onClick={handleCloseConfirmationModal}>
Close
</Button>
</div>
</Modal.Body>
</Modal>  
}

</>


</div>
</div>
</div>
</div>)
:
(<div className='w-full'>

<TableCommponnent 
data={currentData}
recent_invoice={false}
currentPage={currentPage}
handleChangeItemsPerPage={handleChangeItemsPerPage}
itemsPerPage={itemsPerPage}
handlePrevPage={handlePrevPage}
handleNextPage={handleNextPage}
totalPages={totalPages}
onButtonClick={handleButtonClick}
complete={true}
total={total}
count={count}
size={size}
page={page}

/>

</div>)
}
</div>
</div> 
</div> 
    </div>
    }
    
   </>
  )
}

export default ApprovalPage