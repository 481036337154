const formatText = (text) => {
  console.log(text, 'text');
  
  if (typeof text !== 'string') {
    // Handle cases where text is null, undefined, or not a string
    return '';
  }

  if (text === 'dmd_ss') {
    return 'Deputy Managing Director SS';
  }

  // Replace underscores with spaces
  const formattedText = text.replace(/_/g, ' ');

  // Capitalize the first letter of each word
  const capitalizedText = formattedText
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

  console.log(capitalizedText, 'capitalized');
  return capitalizedText;
};

export default formatText;
