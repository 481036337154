import { FetchPurchaseOrderRequest, FetchPurchaseOrderSuccess, FetchPurchaseOrderFailure, ClearTableData } from "../constants/PurchaseOrderConstant";

// Define your initial state
const initialState = {
  
};

// Define your reducer function
export const TableReducer = (state = initialState, action) => {
  switch (action.type) {
    case FetchPurchaseOrderRequest:
      return {
        loading: true
      };
    case FetchPurchaseOrderSuccess:
      return {
        loading: false,
        tableData: action.payload,
        error: null // Clear error when success
      };
    case FetchPurchaseOrderFailure:
      return {
        loading: false,
        error: action.payload
      };
    case ClearTableData:
      return state; // Clear the table data
    default:
      return state;
  }
};

export default TableReducer;
