import React,{useEffect, useState} from 'react'
import {ReactComponent as Vector1 }from '../../assets/images/Group 1.svg'
import {ReactComponent as Vector2 }from '../../assets/images/Group 1 (2).svg'
import {ReactComponent as Vector3}from '../../assets/images/Group 1 (3).svg'
import {ReactComponent as Vector4 }from '../../assets/images/Group 1 (4).svg'
import {ReactComponent as ApprovedIcon }from '../../assets/images/approvedIcon.svg'
import { AgCharts } from "ag-charts-react";
import { NumericFormat } from 'react-number-format';
import axios from 'axios'
import Footer from '../../layout/Footer'
import Loader from '../../components/Loader'
import SessionExpired from './sessionExpired'
// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     ArcElement,
//     Title,
//     Tooltip,
//     Legend,
//   } from 'chart.js';
 // import { Line } from 'react-chartjs-2';
import TableCommponnent from '../../components/TableCommponnent'
import Header from '../apprLyout/Topnav'
import Sidenav from '../apprLyout/Sidenav'
  // ChartJS.register(
  //   CategoryScale,
  //   LinearScale,
  //   PointElement,
  //   LineElement,
  //   Title,
  //   Tooltip,
  //   Legend,
  //   ArcElement
  // );
  
 import {Link} from 'react-router-dom'
 

const Homepage = () => {

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState('');
  const [count, setCount] = useState('');
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [allInvoices, setAllInvoices] = useState([]);
  // const [invoiceData, setInvoiceData] = useState([]);
  const [pendingInvoices, setPendingInvoices] = useState([]);
  const [completedInvoices, setCompletedInvoices] = useState([]);
  const [allInvoicesCount, setAllInvoicesCount] = useState(0);
  const [pendingInvoicesCount, setPendingInvoicesCount] = useState(0);
  // const [completedInvoicesCount, setCompletedInvoicesCount] = useState(0);
  const [approvedInvoicesCount, setApprovedInvoicesCount] = useState(0);
  const [declinedInvoicesCount, setDeclinedInvoicesCount] = useState(0);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const token = localStorage.getItem('accessToken');
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const roles = JSON.parse(localStorage.getItem('roles')) || [];
        const isAdmin = roles.includes("dmd_ss");
  
        const allInvoicesPromise = axios.get(
          `${process.env.REACT_APP_BASE_URL}approvals/v1/${isAdmin ? 'any' : 'get'}/invoice/${isAdmin ? 'all' : 'all'}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const pendingInvoicesPromise = axios.get(
          `${process.env.REACT_APP_BASE_URL}approvals/v1/${isAdmin ? 'any' : 'get'}/invoice/pending`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const completedInvoicesPromise = axios.get(
          `${process.env.REACT_APP_BASE_URL}approvals/v1/${isAdmin ? 'any' : 'get'}/invoice/${isAdmin ? 'completed' : 'all?approved=1'}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const declinedInvoicesPromise = axios.get(
          `${process.env.REACT_APP_BASE_URL}approvals/v1/get/invoice/all?approved=0`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
  
        const [
          allInvoicesResponse,
          pendingInvoicesResponse,
          completedInvoicesResponse,
          declinedInvoicesResponse
        ] = await Promise.all([
          allInvoicesPromise,
          pendingInvoicesPromise,
          completedInvoicesPromise,
          declinedInvoicesPromise
        ]);
  
        // Process completed and declined invoices based on admin role
        if (isAdmin) {
          const filteredCompletedInvoices = completedInvoicesResponse.data.data.results.filter(
            (invoice) => invoice.workflow.completed === true && invoice.workflow.approved === true
          );
          setCompletedInvoices(filteredCompletedInvoices);
          setApprovedInvoicesCount(filteredCompletedInvoices.length);
          localStorage.setItem('completedInvoices', JSON.stringify(filteredCompletedInvoices));
  
          const filteredDeclinedInvoices = completedInvoicesResponse.data.data.results.filter(
            (invoice) => invoice.workflow.completed === true && invoice.workflow.approved === false
          );
          setDeclinedInvoicesCount(filteredDeclinedInvoices.length);
          console.log(filteredDeclinedInvoices, 'declined');
        } else {
          setCompletedInvoices(completedInvoicesResponse.data.data.results);
          setApprovedInvoicesCount(completedInvoicesResponse.data.data.results.length);
          localStorage.setItem('completedInvoices', JSON.stringify(completedInvoicesResponse.data.data.results));
  
          // Set declined invoices from the declinedInvoicesPromise response
          setDeclinedInvoicesCount(declinedInvoicesResponse.data.data.results.length);
          console.log(declinedInvoicesResponse.data.data.results, 'declined');
        }
  
        setAllInvoices(allInvoicesResponse.data.data.results);
        setAllInvoicesCount(allInvoicesResponse.data.data.results.length);
        localStorage.setItem('allInvoices', JSON.stringify(allInvoicesResponse.data.data.results));
  
        setPendingInvoices(pendingInvoicesResponse.data.data.results);
        setPendingInvoicesCount(pendingInvoicesResponse.data.data.results.length);
        localStorage.setItem('pendingInvoices', JSON.stringify(pendingInvoicesResponse.data.data.results));
  
      } catch (error) {
        if (token && error?.response?.status === 401) {
          setRefresh(true);
        } else {
          handleError(error);
        }
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [token]);

  const handleButtonClick = (item) => {
    console.log(item);
  };
  const handleError = (error) => {
    console.error("Invoice fetching error:", error);
    const errorMessage = error?.response?.data?.detail || 'Error Fetching Invoice Data';
    
    if (error?.response?.status === 401) {
      setRefresh(true);
      // handleLogout(msalConfig, userName, navigate); // Uncomment if needed
    } else {
      setError(errorMessage);
    }
  };

// Step 1: Get the current month (0-indexed, 0 = January)
const groupedData = [...completedInvoices].reverse()
   // Only include approved items with a date_signed
  .map(item => ({
      ...item,
      month: new Date(item.workflow.signatures[0].date_signed).toLocaleString('default', { month: 'short' }) // Format month without year
  }))
  .reduce((acc, item) => {
      const month = item.month;
      if (!acc[month]) {
          acc[month] = { month, invoice_count: 0 };
      }
      acc[month].invoice_count += 1; // Increment the count for the month
      return acc;
  }, {});

// Convert the grouped data object to an array
const chartData = Object.values(groupedData);

// Define the chart options
const options = {
  data: chartData,
  series: [
      {
          xKey: 'month',
          yKey: 'invoice_count',
          yName: 'Number of Approved Invoices',
      },
  ],
  axes: [
      {
          type: 'category',
          position: 'bottom',
      },
      {
          type: 'number',
          position: 'left',
      }
  ],
};


  return (
    <div className='min-h-screen'>
    {loading && <Loader/>}
   {refresh ?
  <SessionExpired/>: 
  <>
  <Header />

            <div className="flex main-body ">
              <div className=" hidden md:block">
                <Sidenav/>
              </div>
              <div className="content md:w-[82%] md:ml-[18%] lg:w-[86%] w-full lg:ml-[14%] p-3 mt-[70px]">
        <div className="  grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-3">
        <Link to='/approval/all'className='bg-white p-3 rounded-xl' style={{textDecoration:'none'}}>
            <Vector1/>
        <p className='my-3 text-base' >All Invoices</p>
        <p 
            className='criteria-value' >
            <NumericFormat 
            value={allInvoicesCount} 
            displayType={'text'} 
            thousandSeparator={true} 
            decimalSeparator="."  />
        </p>
    </Link>
    <Link className='bg-white rounded-xl p-3 no-underline ' to='/approval/approved' style={{textDecoration:'none'}}>
            {/* <Vector2/> */}
            <ApprovedIcon/>
        <p className='my-3 text-base' >Approved Invoices</p>
        <p 
            className='criteria-value' >
            <NumericFormat 
            value={approvedInvoicesCount } 
            displayType={'text'} 
            thousandSeparator={true} 
            decimalSeparator="."  />
        </p>
    </Link>
        {/* <div className='bg-white rounded-xl p-3'>
            <ApprovedIcon/>
        <p className='my-3 text-base' >Approved Payment</p>
        <p 
            className='criteria-value' >
            <NumericFormat 
            value={100000} 
            displayType={'text'} 
            thousandSeparator={true} 
            decimalSeparator="."  />
        </p>
    </div> */}
        <Link className='bg-white rounded-xl p-3 no-underline ' to='/approval/pending' style={{textDecoration:'none'}}>
            <Vector3/>
        <p className='my-3 text-base' >Pending Invoices</p>
        <p 
            className='criteria-value' >
            <NumericFormat 
            value={pendingInvoicesCount } 
            displayType={'text'} 
            thousandSeparator={true} 
            decimalSeparator="."  />
        </p>
    </Link>
    <Link className='bg-white rounded-xl p-3 no-underline ' to='/approval/declined' style={{textDecoration:'none'}}>
            <Vector4/>
        <p className='my-3 text-base' >Declined Invoices</p>
        <p 
            className='criteria-value' >
            <NumericFormat 
            value={declinedInvoicesCount } 
            displayType={'text'} 
            thousandSeparator={true} 
            decimalSeparator="."  />
        </p>
    </Link>
       
        </div>
        <div class="request-charts__container box-shadow bg-white my-4 px-4 rounded-lg">
          <p className='font-semibold text-base pt-4'>Invoice chart</p>
          <p className='text-[#696969] mt-2 mb-6 text-base'>Total Invoices approved over time</p>
          <AgCharts options={options}/>
          </div>
          <TableCommponnent 
          data={pendingInvoices}
          recent_invoice={true}
          onButtonClick={handleButtonClick}
          total={total}
          count={count}
          size={size}
          page={page}
          
          />
    </div>
    </div>
    
   
    </>
  }
  {/* <Footer/> */}
     
    </div>
  )
}

export default Homepage