import Logo from '../assets/images/FoodConcepts_Logo-1 1.png';

const Footer = () => {
  return (
    <footer className="bg-white py-4 px-6 flex justify-between items-center border-t h-16">
      <img src={Logo} alt="Food Concepts Logo" className="logo" />
      <p className='text-black'>&copy; 2024 Food Concept</p>
    </footer>
  );
};

export default Footer;
