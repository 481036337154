import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthenticationPages from '../components/AuthenticationPages';
import { Form, Button, InputGroup, Modal } from 'react-bootstrap';
import ApprovalStatusImage from '../assets/images/image 12.png';
import { ReactComponent as EyeIcon } from '../assets/images/eye.svg';
import { ReactComponent as EyeSlashIcon } from '../assets/images/invisible.svg';
import AuthSpinner from '../components/Authspinner';
import axios from 'axios';
import ErrorImage from '../assets/images/errorimage.png';

const CreatePassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const queryParams = location.search.split('?')[1] || '';
  const [identity_hash, token] = queryParams.split('=');

  // useEffect(() => {
  //   console.log('Identity Hash from URL:', identity_hash);
  //   console.log('Token from URL:', token);
  // }, [identity_hash, token]);

  const handleFirstSignIn = () => {
    // Set a flag in localStorage indicating it's the user's first sign-in
    localStorage.setItem('firstSignIn', 'true');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setLoading(true);

    try {
      const setupVerificationResponse = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/vendor/onboard/verifysetup`, {
        identity_hash: identity_hash,
        token: token,
      });

      const newToken = setupVerificationResponse.data.data.token;

      const passwordCreationResponse = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/vendor/onboard/createpassword`, {
        token: newToken,
        new_password: password,
      });

      console.log('Password creation successful:', passwordCreationResponse.data);
      setModalMessage('Password creation successful');
      setShowModal(true);
      // Handle first sign-in
      handleFirstSignIn();
    } catch (error) {
      console.error('Error creating password:', error.message);
      setError(error.message ? error.message : 'An error occurred, try again later');
      setModalMessage(error.response.data.message ? error.response.data.message : 'An error occurred, try again later');
      setShowModal(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setModalMessage('');
    if (!error) {
      // If no error, navigate to sign-in or profile settings based on first sign-in
      const firstSignIn = localStorage.getItem('firstSignIn') === 'true';
      navigate('/sign-in');
    }
  };

  return (
    <div>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Body>
          <div className='d-flex align-items-center flex-column justify-content-center'>
            {error ? (
              <img src={ErrorImage} alt='' className='error-image h-16'></img>
            ) : (
              <img src={ApprovalStatusImage} alt="Approval Status" />
            )}
            <div className={error ? "verify-error" : ""}>
              {error ? 'Oops!' : ''}
            </div>
            {modalMessage && (
              <div className={`approval-status-message text-center ${modalMessage.toLowerCase().includes('error')}`}>
                {modalMessage}
              </div>
            )}
            <Button
              className={`my-2 ${
                error
                  ? ' bg-red-500 text-white border-none py-2 px-4 rounded-md hover:bg-red-600'
                  : 'bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700'
                }`}
              onClick={handleClose}
            >
              {error ? 'Close' : 'Ok'}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <AuthenticationPages
        FormTitle='Register' buttontext='Create Password'
        formaction={handleSubmit}
        PageTitle='Create Password'
        loading={loading}
      >
        <Form.Group>
          <div className="my-3 font-bold text-lg leading-11 tracking-normal text-left underline">
            Step 2 of 2: Create Password
          </div>
          <Form.Label>Password</Form.Label>
          <InputGroup>
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="row-container2 appearance-none border-gray-500 w-full py-2 pl-3 pr-10 text-gray-700 leading-tight focus:outline-none focus:border-2 focus:border-[#115FF5] shadow-none"
            />
            <InputGroup.Text onClick={() => setShowPassword(!showPassword)} className="border-gray-900 focus:outline-none focus:border-2 focus:border-[#115FF5] shadow-none">
              {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <Form.Label className='mt-3'>Confirm Password</Form.Label>
          <InputGroup>
            <Form.Control
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="row-container2 appearance-none border-gray-500 w-full py-2 pl-3 pr-10 text-gray-700 leading-tight focus:outline-none focus:border-2 focus:border-[#115FF5] shadow-none"
            />
            <InputGroup.Text onClick={() => setShowConfirmPassword(!showConfirmPassword)} className="border-gray-900 focus:outline-none focus:border-2 focus:border-[#115FF5] shadow-none">
              {showConfirmPassword ? <EyeIcon /> : <EyeSlashIcon />}
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
        {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <span className="block sm:inline">{error}</span></div>}
        <Button
          className={`authentication-button my-3 py-2 ${loading ? 'd-flex align-items-center justify-content-center gap-1 ' : ''}`}
          type='submit'
          onClick={handleSubmit}
        >
          {loading && <AuthSpinner />} <span>{loading ? '' : 'Create Password'}</span>
        </Button>
      </AuthenticationPages>
    </div>
  );
};

export default CreatePassword;
