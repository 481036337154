
import './App.css';
import { BrowserRouter as Router, Routes, Route,  } from "react-router-dom";
import SignIn from './views/SignIn';
import TwoFactorPage from './views/TwoFactorPage';
import Header from './layout/Header';
import CreateInvoice from './views/CreateInvoice';
import Verification from './views/Verification';
import CreatePassword from './views/CreatePassword';
import ProfileSettings from './views/ProfileSetting';
import CollapsibleTable from './components/Table';
import History from './views/History';
import InvoicePage from './views/InvoicePage';
import Pdf from './views/Pdf';
import HomePage from './views/approval/Homepage'
import ApprovalPage from './views/approval/ApprovalPage'
import Approved from './views/approval/Approved'
import Login from './views/approval/SignIn'
import Declined from './views/approval/Declined';
import ApprovedInvoice from './views/approval/ApprovedInvoice';
import AllInvoice from './views/approval/AllInvoice';
import GrnPage from './views/GRN';
import GrnApproval from './views/grnApproval';
import ResetPassword from './views/RestPassword';
function App() {
  return (
    <Router >
      <Routes>
        <Route path='/sign-in' element={<SignIn/>} />
        <Route path='/verification' element={<Verification/>} />
        <Route path='/sign-up' element={<CreatePassword/>} />
        <Route path='/reset_password' element={<ResetPassword/>} />
        <Route path='/' element={<CreateInvoice/>} />
        <Route path='/profile' element={<ProfileSettings/>} />
        <Route path='/history' element={<History/>} />
        <Route path='/invoice' element={<InvoicePage/>} />
        <Route path='/pdf' element={<Pdf/>} />
        <Route path='/approval/home' element={<HomePage/>} />
        <Route path='/approval' element={<Login/>} />
        <Route path='/approval/pending' element={<ApprovalPage/>} />
        <Route path='/approval/approved' element={<Approved/>} />
        <Route path='/approval/declined' element={<Declined/>} />
        <Route path='/approval/invoice' element={<ApprovedInvoice/>} />
        <Route path='/approval/grn' element={<GrnPage/>} />
        <Route path='/approval/all' element={<AllInvoice/>} />
        <Route path='/approval/pdf' element={<GrnApproval/>} />
      </Routes>
    </Router>
    
    
  );
}

export default App;
