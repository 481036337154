import React from 'react';
import FoodConceptImage from '../assets/images/Group 42348.png';
import VendorImage from '../assets/images/Invoice image.png';
import GroupImages from '../assets/images/image 6.png';

const AuthenticationPages = ({ FormTitle, children, formtext }) => {
  return (
    <div className='w-full max-h-screen'>
      <div className="grid grid-cols-1 md:grid-cols-2 h-full w-full">
        
        {/* Column 1 */}
        <div className="flex flex-col min-h-screen bg-white">
          <div className="mx-3">
            <img className='h-28 mt-1' src={FoodConceptImage} alt="" />
          </div>

          {/* Form content */}
          <div className="mx-auto flex flex-col h-full md:w-[55%] w-[75%] my-3">
            <h1 className='text-4xl font-extrabold'>{FormTitle}</h1>
            <p className='mt-2 text-base text-[#222121]'>{formtext}</p>
            <div>
              {children}
            </div>
          </div>
          <img className='align-self-end w-full mt-auto h-32 object-cover px-3 sm:hidden block' src={GroupImages} alt="" />

          <div className="align-self-end mt-auto gap-3 hidden md:flex  md:mx-auto text-gray">
          
            <span>Copyright ©2024</span>
            <span className='border-r pr-2 border-r-gray-900 mb-3'>Food Concepts</span>
            <span>All rights reserved</span>
          </div>
        </div>

        {/* Column 2 - Hidden on small screens */}
        <div className="hidden md:flex  flex-col shadow-md h-screen">
        <div className="h-full justify-center items-center flex">
        <img className='max-w-full h-[55%] object-contain' src={VendorImage} alt="Vendor" />
        </div>
            
         
              <img className='mx-auto mt-auto self-end object-contain  h-28 ' src={GroupImages} alt="" />
            
            </div>
           
        
      </div>
    </div>
  );
}

export default AuthenticationPages;
